<template>
  <div v-if="navigation" class="add-new-item-admin">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.navigationUpdate')"
      :title="$t('messages.navigationUpdate')"
      @submitPressed="submitPressed"
    />
    <navigation-form
      :navigation-obj="navigation"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import NavigationForm from "./components/NavigationForm.vue";
import Toast from "@/components/Toast.vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    TitleBar,
    NavigationForm,
  },
  data() {
    return {
      action: null,
      navigation: null,
    };
  },
  created() {
    this.loadNavigation();
  },
  methods: {
    loadNavigation() {
      const position = this.$route.params.position;
      this.$Navigations
        .getResource({ id: position })
        .then((response) => {
          if (response.status === 200) {
            this.navigation = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
